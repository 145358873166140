<app-backlink route="artists" linkText="artists" class="smooth-fade-in"></app-backlink>
<app-error *ngIf="error"></app-error>
<div class="artist-wrapper smooth-fade-in" *ngIf="artist">
    <h1 class="main-site-title">{{ artist.name }}</h1>
    <div class="youtube" *ngIf="artist.youtube_video">
        <iframe 
            width="100%" 
            height="320" 
            [src]="sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/' + artist.youtube_video)" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
        </iframe>
    </div>
    <div class="artist-content">
        <div class="section-a">
            <div [innerHTML]="artist.artist_description" class="descr"></div>
        </div>
        <div class="section-b">
            <div class="platforms">
                <a *ngIf="artist.bandcamp" href={{artist.bandcamp}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/bandcamp.svg'" class="icon"></div>
                    <div>Bandcamp</div>
                </a>
                <a *ngIf="artist.discogs" href={{artist.discogs}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/solid/angle-right.svg'" class="icon"></div>
                    <div>Discogs</div>
                </a>
                <a *ngIf="artist.facebook" href={{artist.facebook}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/facebook-f.svg'" class="icon"></div>
                    <div>Facebook</div>
                </a>
                <a *ngIf="artist.hearthis" href={{artist.hearthis}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/solid/angle-right.svg'" class="icon"></div>
                    <div>Hearthis</div>
                </a>
                <a *ngIf="artist.instagram" href={{artist.instagram}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/instagram.svg'" class="icon"></div>
                    <div>Instagram</div>
                </a>
                <a *ngIf="artist.mixcloud" href={{artist.mixcloud}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/mixcloud.svg'" class="icon"></div>
                    <div>Mixcloud</div>
                </a>
                <a *ngIf="artist.soundcloud" href={{artist.soundcloud}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/soundcloud.svg'" class="icon"></div>
                    <div>Soundcloud</div>
                </a>
                <a *ngIf="artist.spotify" href={{artist.spotify}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/spotify.svg'" class="icon"></div>
                    <div>Spotify</div>
                </a>
                <a *ngIf="artist.website" href={{artist.website}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/solid/link.svg'" class="icon"></div>
                    <div>Website</div>
                </a>
                <a *ngIf="artist.youtube" href={{artist.youtube}} target="_blank" class="social-media-link">
                    <div [inlineSVG]="'/assets/icons/font-awesome/brands/youtube.svg'" class="icon"></div>
                    <div>YouTube</div>
                </a>
            </div>
        </div>
        <div class="section-c">
            <div class="soundcloud" *ngIf="artist.soundcloud_playlist">
                <iframe 
                    width="100%" 
                    height="320" 
                    scrolling="no" 
                    frameborder="no" 
                    allow="autoplay" 
                    [src]="sanitizer.bypassSecurityTrustResourceUrl('https://w.soundcloud.com/player/?url=' + artist.soundcloud_playlist + '&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true')">
                </iframe>
                <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
                    <a href="https://soundcloud.com/trustinwax" title="Trust in Wax" target="_blank" style="color: #cccccc; text-decoration: none;">Trust in Wax</a> · <a href="https://soundcloud.com/trustinwax/sets/atwashere-tracks-remixes-and" 
                    title="Playlist on Souncloud" 
                    target="_blank" 
                    style="color: #cccccc; text-decoration: none;">Atwashere! | Tracks, Remixes and Edits</a>
                </div>
            </div>
        </div>
    </div>
</div>
