import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import 'hammerjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isHome = false;

  constructor(private router: Router) {}

  ngOnInit() {
    
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        if(evt.url === '/' || evt.url === '/#/signin' || evt.url === '/#/cart') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
        window.scrollTo(0, 0)
    });
  }
}
