<div class="blog-wrapper smooth-fade-in">
    <div class="headline-wrapper" *ngIf="!error">
        <h1 class="main-site-title">Blog</h1>
        <ng-select
            class="selector"
            (change)="ngSelectCategory($event)"
            [items]="categorySelection"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="activeCategoryId">
        </ng-select>
    </div>
    <app-error *ngIf="error"></app-error>
    <div *ngFor="let entry of blogEntries" class="blog-entry" (click)="openEntry($event, entry.url_suffix)">

        <div class="teaser">
            <div class="image-wrapper">
                <div class="sbl-circ-ripple" *ngIf="!entry.image"></div>
                <img *ngIf="entry.image" [src]="entry.image.data.thumbnails[4].url" class="fade-in">
            </div>
            <div class="entry-text">
                <div class="title">
                    {{ entry.title }}
                    <div class="date">
                        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M3.5 0v5m8-5v5m-10-2.5h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="currentColor"></path></svg>  
                        {{ entry.article_date | date: 'dd.MM.yyyy' }}
                    </div>
                </div>

                <div class="teaser-text">
                    <div class="txt" [innerHTML]="entry.teasertext"></div>
                    <div class="meta">
                        <div class="categories">
                            <div class="category-chip" *ngFor="let category of entry.categories" (click)="setCategory($event, category.blog_categories_id.url_suffix)">
                                {{ category.blog_categories_id.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>