<div class="home-wrapper smooth-fade-in">
    <div class="bg">
        <div class="content">
            <div class="section-wax">
                <h4 class="headline head-wax">wax (wæks)</h4>
                <div class="definition wax">
                    wax 1. cera n; paraffin [chem.] n; sealingwax n; earwax (cerumen) n; common black pitch n; beeswax n; bone wax n; shoe polish n; wax candle f, wax doll f. wax head f; (transitive) torub, smear, stiffen, coat, polish, etc, with wax, to remove (hair) [wax treatment], to wax lyrical aboutsth. wax 2. [slang] a phonograph record
                </div>
            </div>
            <div class="section-trust">
                <h4 class="headline head-trust">trust (trʌst)</h4>
                <div class="definition trust">
                    trust 1. faith (in so.|sth.) n; firm belief (in so.|sth.) n; trustee relationship [finan.] n; credit n; deposit n; pledge n; foundation [finan.] n; cartel n; syndicate n; -company n; in|on trust [in goodfaith]; to give credit to so.; position of trust
                </div>
            </div>
        </div>
    </div>
</div>