import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReleasesService {

  releases;

  constructor(
    private apiService: ApiService,
  ) { }

  async getReleases() {
    if(this.releasesExist()) {
      return this.releases;
    } else {
      await this.fetchReleases();
      return this.releases;
    }
  }

  async fetchReleases() {
    await this.apiService.getReleases().toPromise().then((res: any) => {
      {
        this.releases = res.data
/*         this.releases.sort(function(a, b) {
          var keyA = new Date(a.release_day),
            keyB = new Date(b.release_day);
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        this.assignDetails(); */
      }
    });
  }

  // adds cover img and artist to release
  async assignDetails() {
    let currEntry = {};
    for(var i = 0; i < this.releases.length; i++) {
      currEntry = this.releases[i];
      await this.getFile(this.releases[i].cover).then(res => {
        let img = {
          img: res
        };
        Object.assign(currEntry, img)
      });

      await this.getArtist(this.releases[i].label_artist).then(res => {
        let artist = {
          artist: res
        };
        Object.assign(currEntry, artist)
      })
    }
  }

  async getArtist(id) {
    let result;
    await this.apiService.getArtist(id).toPromise().then((res: any) => {
      result = res.data;
    });
    return result;

  }

  async getFile(id) {
    let result;
    await this.apiService.getFile(id).toPromise().then((res: any) => {
      result = res.data.data;
    });
    return result;
  }

  getReleaseByRouteParam(param: String) {
    for(let release of this.releases) {
      if(release.url_suffix === param) {
        return release;
      }
    }
  }


  releasesExist() {
    if (this.releases) {
      return true;
    } else {
      return false;
    }
  }

}
