import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { PlyrModule } from 'ngx-plyr';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { BlogComponent } from './components/blog/blog.component';
import { ProductListComponent } from './components/shop/product-list/product-list.component';
import { ProductDetailComponent } from './components/shop/product-detail/product-detail.component';
import { MainComponent } from './components/layout/main/main.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { NotFoundComponent } from './components/layout/not-found/not-found.component';
import { OverviewComponent } from './components/shop/overview/overview.component';
import { ProductsVinylComponent } from './components/shop/products-vinyl/products-vinyl.component';
import { VinylDetailComponent } from './components/shop/products-vinyl/vinyl-detail/vinyl-detail.component';
import { ArtistsComponent } from './components/artists/artists.component';
import { ArtistsDetailComponent } from './components/artists/artists-detail/artists-detail.component';
import { TextPageComponent } from './components/layout/text-page/text-page.component';
import { BacklinkComponent } from './components/layout/backlink/backlink.component';
import { ReleasesComponent } from './components/releases/releases.component';
import { ReleaseDetailComponent } from './components/releases/release-detail/release-detail.component';
import { HomeComponent } from './components/home/home.component';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorComponent } from './components/layout/error/error.component';
import { BlogEntryComponent } from './components/blog/blog-entry/blog-entry.component';
import { BlogCategoriesComponent } from './components/blog/blog-categories/blog-categories.component';

@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
    ProductListComponent,
    ProductDetailComponent,
    MainComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,
    NotFoundComponent,
    OverviewComponent,
    ProductsVinylComponent,
    VinylDetailComponent,
    ArtistsComponent,
    ArtistsDetailComponent,
    TextPageComponent,
    BacklinkComponent,
    ReleasesComponent,
    ReleaseDetailComponent,
    HomeComponent,
    ErrorComponent,
    BlogEntryComponent,
    BlogCategoriesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    PlyrModule,
    NgxGalleryModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    NgSelectModule,
    InlineSVGModule.forRoot(),
    HttpClientModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
