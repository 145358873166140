import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isHome = true;
  spin = false;
  mobileMenuOpen = false;
  activeRoute = '/';

  constructor(
    private router: Router,
    private titleService: Title
    ) { }

  ngOnInit(): void {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      if(evt.url === '/' || evt.url === '/#/signin' || evt.url === '/#/cart') {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
      this.activeRoute = evt.url;
      window.scrollTo(0, 0);
    });

  }

  setTitle(title: string) {
    this.spinLogo();
    this.titleService.setTitle(title);
  }

  spinLogo() {
    this.spin = true;
    setTimeout(() => {
      this.spin = false;
    }, 1000);
  }

  menuToggle() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  mobileRoute(route: string, title: string) {
    
    this.router.navigateByUrl(route);
    this.titleService.setTitle(title);
    setTimeout(() => {
      this.menuToggle();
    }, 300);
  }
}
