import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReleasesService } from 'src/services/releases.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-release-detail',
  templateUrl: './release-detail.component.html',
  styleUrls: ['./release-detail.component.scss']
})
export class ReleaseDetailComponent implements OnInit {

  routeParam;
  release; 
  error = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private releaseService: ReleasesService,
    public sanitizer: DomSanitizer,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.routeParam = this.route.snapshot.paramMap.get('id');
    this.releaseSetup();
  }

  releaseSetup() {
    if (this.releaseService.releasesExist()) {
      this.error = false;
      this.release = this.releaseService.getReleaseByRouteParam(this.routeParam);
      if(this.release === undefined) {
        this.router.navigate(['404']);
      }
      this.titleService.setTitle('Trust In Wax - ' + this.release.title);
    } else {
      this.releaseService.fetchReleases().then(res => {
        this.error = false;
        console.log(res)
        this.release = this.releaseService.getReleaseByRouteParam(this.routeParam);
        console.log(this.release)
        if(this.release === undefined) {
          this.router.navigate(['404']);
        }
        this.titleService.setTitle('Trust In Wax - ' + this.release.title);
      }).catch(() => {
        this.error = true;
      })
    }
  }

}
