import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  baseUrl = environment.api;
  generalTexts;

  constructor(protected http: HttpClient) { }

  getGeneralTexts() {
    return this.generalTexts;
  }

  async fetchGeneralTexts() {
    await this.http.get(this.baseUrl + 'page_texts').toPromise().then((res: any) => {
      this.generalTexts = res.data
    })
  }

  getImprintText() {
    for (let text of this.generalTexts) {
      if (text.title === 'imprint') {
        return text;
      }
    }
  }

  getPrivacyText() {
    for (let text of this.generalTexts) {
      if (text.title === 'privacy') {
        return text;
      }
    }
  }

  generalTextsExist() {
    if (this.generalTexts) {
      return true;
    } else {
      return false;
    }
  }

}
