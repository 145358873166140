import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }
  
  id;
  product;
  imageDetails;
  error;

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getProduct(this.id);
  }

  async getProduct(id) {
    await this.apiService.getProduct(id).toPromise().then((res: any) => {
      this.product = res.data,
      console.log(res),
      this.getProductImage(this.product.image);
    }).catch( err =>
          this.error = err.error.error,
    );
  }

  async getProductImage(id) {
    await this.apiService.getFile(id).toPromise().then((res: any) => {
      this.imageDetails = res.data.data;
    });
  }

}
