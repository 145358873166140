import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductDetailComponent } from './components/shop/product-detail/product-detail.component'
import { OverviewComponent } from './components/shop/overview/overview.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogEntryComponent } from './components/blog/blog-entry/blog-entry.component';
import { HomeComponent } from './components/home/home.component';
import { ReleasesComponent } from './components/releases/releases.component';
import { ContactComponent } from './components/contact/contact.component';
import { NotFoundComponent } from './components/layout/not-found/not-found.component';
import { VinylDetailComponent } from './components/shop/products-vinyl/vinyl-detail/vinyl-detail.component';
import { ArtistsComponent } from './components/artists/artists.component';
import { ArtistsDetailComponent } from './components/artists/artists-detail/artists-detail.component';
import { TextPageComponent } from './components/layout/text-page/text-page.component';
import { ReleaseDetailComponent } from './components/releases/release-detail/release-detail.component';
import { BlogCategoriesComponent } from './components/blog/blog-categories/blog-categories.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'artists', component: ArtistsComponent },
  { path: 'artists/:id', component: ArtistsDetailComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/entry/:id', component: BlogEntryComponent },
  { path: 'blog/category/:id', component: BlogCategoriesComponent },
  { path: 'releases', component: ReleasesComponent },
  { path: 'releases/:id', component: ReleaseDetailComponent },
  { path: 'shop', component: OverviewComponent },
  { path: 'shop/vinyl', component: OverviewComponent },
  { path: 'shop/vinyl/:id', component: VinylDetailComponent },
  { path: 'shop/merch', component: OverviewComponent },
  { path: 'shop/merch/detail/:id', component: ProductDetailComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'label', component: TextPageComponent },
  { path: 'imprint', component: TextPageComponent },
  { path: 'privacy', component: TextPageComponent },
  { path: '404', component: NotFoundComponent},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
