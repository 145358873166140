<h1 class="main-site-title smooth-fade-in">Contact</h1>

<div class="contact-wrapper">
    <div *ngIf="sending" class="sending">
        <div class="sbl-circ-ripple"></div>
    </div>
    <div *ngIf="success" class="sent">
        <p><strong>Message sent!</strong></p>
        <p>
            We'll reach out back to you as soon as possible. 
        </p>
    </div>
    <div *ngIf="err" class="error">
        <p><strong>Something went wrong!</strong></p>
        <p>please check your internet connection or try again later...</p>
    </div>
    <div *ngIf="validateNote" class="hint">
        <p><strong>Something is missing here...</strong></p>
        <p>please make sure to correctly fill out all fields.</p>
    </div>
    <form *ngIf="!success && !sending" class="smooth-fade-in">
        <div class="row">
            <mat-form-field>
                <mat-label>Name*</mat-label>
                <input matInput [(ngModel)]="name" name="name">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <mat-label>E-Mail*</mat-label>
                <input matInput [(ngModel)]="email" name="email">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <mat-label>Message*</mat-label>
                <textarea matInput [(ngModel)]="message" name="message"></textarea>
            </mat-form-field>
        </div>
        <div class="row">
            <section>
                <mat-checkbox class="example-margin" [(ngModel)]="privacyRead" name="privacy">
                    I noticed the <a [routerLink]="['/privacy/']" target="_blank">privacy policy</a>
                </mat-checkbox>
            </section>
        </div>
        <button mat-button (click)="submit($event)" class="btn btn-submit">
            <div class="inner">
                Send message
                <div [inlineSVG]="'/assets/icons/teenyicons/telegram.svg'" class="icon"></div>
            </div>
        </button>
    </form>
</div>