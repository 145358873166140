import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/services/blog.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-entry',
  templateUrl: './blog-entry.component.html',
  styleUrls: ['./blog-entry.component.scss']
})
export class BlogEntryComponent implements OnInit {

  routeParam;
  blogEntry: any;
  error = false;
  entry: SafeHtml;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
    private titleService: Title
  ) { }

  ngOnInit(): void {
      this.routeParam = this.route.snapshot.paramMap.get('id');
      this.entrySetup();
  }

  entrySetup() {
    if(this.blogService.blogEntriesExist()) {
      this.error = false;
      this.blogEntry = this.blogService.getEntryByRouteParam(this.routeParam);
      if(this.blogEntry === undefined) {
        this.router.navigate(['404']);
      }
      this.titleService.setTitle('Trust In Wax - ' + this.blogEntry.title);
      this.entry = this.sanitizer.bypassSecurityTrustHtml(this.blogEntry.blog_entry);

    } else {
      this.blogService.fetchBlogEntries().then(res => {
        this.error = false;
        this.blogEntry = this.blogService.getEntryByRouteParam(this.routeParam);
        if(this.blogEntry === undefined) {
          this.router.navigate(['404']);
        }
        this.titleService.setTitle('Trust In Wax - ' + this.blogEntry.title);
        this.entry = this.sanitizer.bypassSecurityTrustHtml(this.blogEntry.blog_entry);
      }).catch(() => 
        this.error = true
      )
    }
  }

}
