import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-text-page',
  templateUrl: './text-page.component.html',
  styleUrls: ['./text-page.component.scss']
})
export class TextPageComponent implements OnInit {

  routePath;
  pageTexts;
  pageText;
  headline;

  error = false;

  constructor(
    private route: ActivatedRoute,
    private generalService: GeneralService,
    private titleService: Title
    ) { }

  ngOnInit(): void {
    this.routePath = this.route.snapshot.routeConfig.path;
    this.loadTexts();
  }


  async loadTexts() {

    if (this.generalService.generalTextsExist()) {
      this.pageTexts = this.generalService.getGeneralTexts();
      this.getTextByRoutePath();
      this.error = false;
    } else {
      await this.generalService.fetchGeneralTexts().then(res => {
        this.pageTexts = this.generalService.getGeneralTexts(),
        this.error = false;
        this.getTextByRoutePath();
      }).catch(() => {
        this.error = true;
      });
    }
  }

  getTextByRoutePath() {
    for(let i = 0; i <= this.pageTexts.length -1; i++) {
      if(this.pageTexts[i].route === this.routePath) {
        this.pageText = this.pageTexts[i].page_text;
        this.headline = this.pageTexts[i].title;
        this.titleService.setTitle('Trust In Wax - ' + this.pageTexts[i].title);
      }
    }
  }

}
