<div class="smooth-fade-in">
    <h2>Product Detail</h2>
    <button [routerLink]="['/shop/merch']" routerLinkActive="active">Back to overview</button>

    <div *ngIf="error">
        <div>Error: {{error.code}}</div>
        <div>{{error.message}}</div>
    </div>
    <div *ngIf="product" class="product-details">

        <div class="name">{{ product.name }}</div>
        <div class="price">{{ product.price }}</div>
        <div [innerHTML]="product.teaser"></div>
        <div class="image-wrapper">
            <img *ngIf="!imageDetails" src="./../../../assets/icons/puff.svg">
            <img *ngIf="imageDetails" [src]="imageDetails.thumbnails[3].url" class="fade-in">
        </div>


        <button 
        *ngIf="imageDetails"
        class="snipcart-add-item fade-in"
        [attr.data-item-name]="product.name"
        [attr.data-item-id]="'merch_' + product.id"
        [attr.data-item-price]="product.price"
        [attr.data-item-image]="imageDetails.thumbnails[3].url"
        [attr.data-item-description]="product.teaser"
        [attr.data-item-has-taxes-included]="true"
        [attr.data-item-url]="">
            Add to cart
        </button>
    </div>
</div>