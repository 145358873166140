<app-error *ngIf="error"></app-error>
<div class="hint">
    This section is comming soon :)
</div>
<!-- <div *ngFor="let product of products" class="product-entry">

    <div class="name">{{ product.name }}</div>
    <div class="price">{{ product.price }}</div>
    <div [innerHTML]="product.teaser"></div>
    <div class="image-wrapper">
        <div class="sbl-circ-ripple" *ngIf="!product.thumbnails"></div>
        <img *ngIf="product.thumbnails" [src]="product.thumbnails[3].url" class="fade-in">
    </div>


    <button [routerLink]="['/shop/merch/detail', product.id]" routerLinkActive="active">Details</button>
    <button *ngIf="product.thumbnails"
        class="snipcart-add-item"
        [attr.data-item-name]="product.name"
        [attr.data-item-id]="'merch_' + product.id"
        [attr.data-item-price]="product.price"
        [attr.data-item-description]="product.teaser"
        [attr.data-item-image]="product.thumbnails[3].url"
        [attr.data-item-has-taxes-included]="true"
        [attr.data-item-url]="">
        Add to cart
    </button>

</div> -->