<app-backlink route="shop/vinyl" linkText="overview" class="smooth-fade-in"></app-backlink>
<div *ngIf="error">
    <div>Error: {{error.code}}</div>
    <div>{{error.message}}</div>
</div>

<div *ngIf="product" class="product-details">
    <div class="left">
        <div class="image-wrapper">
            <div *ngIf="!imageDetails" class="sbl-circ-ripple"></div>
            <ngx-gallery *ngIf="imageDetails" [options]="galleryOptions" [images]="galleryImages" class="fade-in"></ngx-gallery>
        </div>
        <div *ngIf="product.audio" class="desc-title audio-preview fade-in">Audio Preview</div>
        <div 
            *ngIf="product.preview_sounds"
            class="fade-in"
            plyr 
            style="width: 300px;" 
            plyrType="audio"
            [plyrPlaysInline]="true"
            [plyrOptions]="plyrOptions"
            [plyrSources]="product.preview_sounds.data.full_url" 
            (plyrInit)="player = $event" 
            (plyrPlay)="played($event)">
        </div>
        <div *ngIf="product.audio" class="desc-title fade-in">Tracklist</div>
        <div [innerHTML]="product.tracklist" class="tracklist"></div>

    </div>

    <div class="descr">
        <div class="section-1">
            <div class="title">
                <div class="name">{{ product.name }}</div>
<!--                 <div class="tiw-no">{{ product.tiw_no }}</div> -->
            </div>
        </div>
        <div class="price">{{ product.price }} €<p class="vat">incl VAT + Shipping</p></div>
        <div [innerHTML]="product.description" class="description"></div>
    
        <button 
            mat-button
            *ngIf="galleryImages"
            class="btn fade-in snipcart-add-item"
            [attr.data-item-name]="product.name"
            [attr.data-item-id]="'vinyl_' + product.id"
            [attr.data-item-weight]="product.weight"
            [attr.data-item-price]="product.price"
            [attr.data-item-description]="product.teaser"
            [attr.data-item-image]="galleryImages[0].medium"
            [attr.data-item-has-taxes-included]="true"
            [attr.data-item-url]="">
            <div class="inner">
                Add to cart
                <div [inlineSVG]="'/assets/icons/teenyicons/add-to-cart.svg'" class="icon"></div>
            </div>
        </button>
    </div>
</div>