import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  products;
  error = false;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.getProducts().toPromise().then((res: any) => {
      this.error = false,
      this.products = res.data,
      this.getProductImages();
    }).catch(() => {
      this.error = true;
    })
  }

  async getProductImages() {
    let currEntry = {};
    for(var i = 0; i < this.products.length; i++) {
      currEntry = this.products[i];
      await this.getFile(this.products[i].image).then(res => {
        Object.assign(currEntry, res)
      });
    }
    console.log(this.products);
  }

   async getFile(id) {
    let result;
    await this.apiService.getFile(id).toPromise().then((res: any) => {
      result = res.data.data;
    });
    return result;
  }

}
