import { Component, OnInit } from '@angular/core';
import { ReleasesService } from 'src/services/releases.service';


@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss']
})
export class ReleasesComponent implements OnInit {

  releases;
  error = false;

  constructor(
    private releaseService: ReleasesService
  ) { }

  ngOnInit(): void {
    this.releaseService.getReleases().then(res => {
      this.releases = res;
      this.error = false;
    }).catch(() => {
      this.error = true;
    })
  }

}
