import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  blogEntries;
  blogCategories

  constructor(
    private apiService: ApiService,
  ) { }

  async getBlogEntries() {
    if(this.blogEntriesExist()) {
      return this.blogEntries;
    } else {
      await this.fetchBlogEntries();
      return this.blogEntries;

    }
  }

  async getBlogCategories() {
    if(this.blogCategoriesExist()) {
      return this.blogCategories;
    } else {
      await this.fetchBlogCategories();
      return this.blogCategories;
    }
  }

  async fetchBlogCategories() {
    await this.apiService.getBlogCategories().toPromise().then((res: any) => {
      this.blogCategories = res.data
    });
  }

  getEntryByRouteParam(param: String) {
    for(let entry of this.blogEntries) {
      if(entry.url_suffix === param) {
        return entry;
      }
    }
  }

  isValidEntryByRouteParam(param: String) {
    let r = false;
    for(let entry of this.blogCategories) {
      if (entry.url_suffix === param) {
        r = true;
      }
    }
    return r;
  }

  async fetchBlogEntries() {
    await this.apiService.getBlogEntries().toPromise().then((res: any) => {
      {
        this.blogEntries = res.data
        //this.getBlogImages()
      }
    });
  }

  async getBlogImages() {
    let currEntry = {};
    for(var i = 0; i < this.blogEntries.length; i++) {
      currEntry = this.blogEntries[i];
      await this.getFile(this.blogEntries[i].image).then(res => {
        let img = {
          img: res
        };
        Object.assign(currEntry, img)
      });
    }
  }

  async getFile(id) {
    let result;
    await this.apiService.getFile(id).toPromise().then((res: any) => {
      result = res.data.data;
    });
    return result;
  }

  blogCategoriesExist() {
    if (this.blogCategories) {
      return true;
    } else {
      return false;
    }
  }

  blogEntriesExist() {
    if (this.blogEntries) {
      return true;
    } else {
      return false;
    }
  }
}
