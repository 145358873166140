<div class="artists-wrapper smooth-fade-in">
    <h1 class="main-site-title" *ngIf="!error">Artists</h1>
    <app-error *ngIf="error"></app-error>
    <div class="artists">
        <ng-container *ngFor="let artist of artists; let i = index">
            <div *ngIf="artist.list_as_label_artist" class="artist" [routerLink]="['/artists/', artist.url_suffix]">
                <div class="image-wrapper">
                    <div class="sbl-circ-ripple" *ngIf="!artist.artist_image"></div>
                    <img *ngIf="artist.artist_image" [src]="artist.artist_image.data.thumbnails[3].url" class="fade-in">
                </div>
                <div class="name">{{ artist.name }}</div>
            </div>
        </ng-container>
    </div>
</div>