import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/services/blog.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  routeParam;
  blogEntries = [];
  blogCategories = [];
  categorySelection = [];
  activeCategoryId;
  error = false;
  routeSub: Subscription;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.routeSubscription();
    this.blogSetup().then(() => {
      this.getCategories();
    });
    this.titleService.setTitle('Trust In Wax - Blog');
  }

  routeSubscription() {
    this.routeSub = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  setRouteParam(param) {
    this.routeParam = param;
  }

  async blogSetup() {
    this.routeParam = this.route.snapshot.paramMap.get('id');
    await this.blogService.getBlogEntries().then(res => {
      this.error = false;
      this.blogEntries = res;
      this.activeCategoryId = 0;
    }).catch(() => {
      this.error = true;
    })
  }

  async getCategories() {
    await this.blogService.getBlogCategories().then(cats => {
      this.error = false;
      this.blogCategories = cats;

      let options = [{
        id: 0,
        name: 'All categories',
        url_suffix: ''
      }];

      for (let cat of cats) {
        let option = {
          id: cat.id,
          name: cat.name,
          url_suffix: cat.url_suffix
        }
        options.push(option);
      }

      this.categorySelection = options;

      if (this.routeParam !== null) {
        if(this.blogService.isValidEntryByRouteParam(this.routeParam)) {
          this.setActiveCategory();
        } else {
          this.router.navigate(['404']);
        }
      }
    }).catch(() => {
      this.error = true;
    });
  }


  setActiveCategory() {
    for(let cat of this.blogCategories) {
      if (cat.url_suffix === this.routeParam) {
        this.activeCategoryId = cat.id;
        this.filterByCategory();
      }
    }
  }

  filterByCategory() {
    let filtered = [];
    for(let entry of this.blogEntries) {
      for (let category of entry.categories) {
        if(category.blog_categories_id.url_suffix === this.routeParam) {
          filtered.push(entry);
        }
      }
    }
    this.blogEntries = filtered;
  }

  ngSelectCategory(cat) {
    if(cat) {
      if(cat.id !== undefined) {
        this.activeCategoryId = cat.id;
      } 
      
      if(cat.url_suffix === undefined || cat.url_suffix === '') {
        this.router.navigate(['/blog/']);
      } else {
        this.setCategory(new Event(''), cat.url_suffix);
      }
    } else {
      this.activeCategoryId = 0;
      this.router.navigate(['/blog/']);
    }

  }

  openEntry(e: Event, url_suffix: string) {
    e.stopPropagation();
    e.preventDefault();
    this.router.navigate(['/blog/entry/' + url_suffix]);
  }

  setCategory(e: Event, url_suffix) {
    e.stopPropagation();
    e.preventDefault();
      this.blogSetup().then(() => {
        this.router.navigate(['/blog/category/' + url_suffix]);
        this.setRouteParam(url_suffix);
        this.setActiveCategory();
      })
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}
