import { Component, OnInit } from '@angular/core';
import { ArtistsService } from 'src/services/artists.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {

  artists;
  error = false;

  constructor(
    private artistService: ArtistsService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Trust In Wax - Artists');
    this.artistService.getArtists().then(res => {
      this.artists = res;
      this.error = false;
    }).catch(() => {
      this.error = true;
    });
  }

}
