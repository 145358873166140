import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/services/shop.service';
import { Subscription } from 'rxjs';
declare var require: any

@Component({
  selector: 'app-products-vinyl',
  templateUrl: './products-vinyl.component.html',
  styleUrls: ['./products-vinyl.component.scss']
})
export class ProductsVinylComponent implements OnInit {

  player: Plyr;
  plyrOptions = {
    controls: ['play', 'progress']
  }
  products;
  error = false;
  sortBy;
  sortingSub: Subscription;
  url = '/app/shop'

  constructor(
    private shopService: ShopService
  ) { }

  ngOnInit() {
    this.shopService.getVinylProducts().then(res => {
      this.error = false;
      this.products = res;
      this.sortSetup();
    }).catch(() => {
      this.error = true;
    });
  }

  async sortSetup() {
    this.sortingSub = await this.shopService.getSortingSetting().subscribe(setting => {
      this.setSortSetting(setting);
    });
  }

  ngOnDestroy() {
    this.sortingSub.unsubscribe();
  }

  getAudioSource(product) {
    let sources: Plyr.Source[] = [
      {
        src: product.audio.full_url,
        type: 'audio'
      }
    ];
    return sources;
  }

  played(event: Plyr.PlyrEvent) {
    
  }

  setSortSetting(setting: string) {
    this.sortBy = setting;
    this.sortProducts();
  }

  sortProducts() {
    var arraySort = require('array-sort');
    switch (this.sortBy) {
      case 'date-new':
        arraySort(this.products, 'id', {reverse: true});
        break;
      
      case 'date-old':
        arraySort(this.products, 'id');
        break;
      
      case 'name-desc':
        arraySort(this.products, 'name');
        break;

      case 'name-asc':
        arraySort(this.products, 'name', {reverse: true});
        break;
      
      case 'price-desc':
        arraySort(this.products, 'price');
        break;

      case 'price-asc':
        arraySort(this.products, 'price', {reverse: true});
        break;
      
      default:
        break;
    }
  }

}
