import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ArtistsService {

  artists;

  constructor(
    private apiService: ApiService,
  ) { }


  getArtistByRouteParam(param: String) {
    for(let artist of this.artists) {
      if(artist.url_suffix === param) {
        return artist;
      }
    }
  }

  async getArtists() {
    if(this.artistsExist()) {
      return this.artists;
    } else {
      await this.fetchArtists();
      return this.artists;
    }
  }

  async fetchArtists() {
    await this.apiService.getArtists().toPromise().then((res: any) => {
      {
        this.artists = res.data
        //this.getArtistImages()
      }
    });
  }

  artistsExist() {
    if (this.artists) {
      return true;
    } else {
      return false;
    }
  }

  async getArtistImages() {
    let currEntry = {};
    for(var i = 0; i < this.artists.length; i++) {
      currEntry = this.artists[i];
      await this.getFile(this.artists[i].artist_image).then(res => {
        let img = {
          img: res
        };
        Object.assign(currEntry, img)
      });
    }
  }

  async getFile(id) {
    let result;
    await this.apiService.getFile(id).toPromise().then((res: any) => {
      result = res.data.data;
    });
    return result;
  }
}
