<div class="releases-wrapper smooth-fade-in">
    <h1 class="main-site-title" *ngIf="!error">Releases</h1>
    <app-error *ngIf="error"></app-error>
    <div class="releases">
        <div class="release" *ngFor="let release of releases" [routerLink]="['/releases/', release.url_suffix]">
            <div class="image-wrapper">
                <div class="sbl-circ-ripple" *ngIf="!release.cover"></div>
                <img *ngIf="release.cover" [src]="release.cover.data.full_url" class="fade-in">
            </div>
            <div class="details">
                <div class="artist-name">
                    <span *ngIf="release.label_artist" class="artist">
                        <div>{{release.label_artist.name}}</div>
                    </span>
                </div>
                <div class="title">{{release.title}}</div>
            </div>
        </div>
    </div>
</div>