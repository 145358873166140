<h1 class="main-site-title smooth-fade-in">Shop</h1>

<div class="options smooth-fade-in">
    <div class="categories-wrapper">
        <div class="categories">
            <div *ngFor="let category of categories">
                <div (click)="setLink(category)" class="category-selector" [ngClass]="{'active': category === activeCategory}">{{category}}</div>
            </div>
        </div>
    </div>

    <div class="sorting">
        <ng-select
            class="selector"
            (change)="updateSorting()"
            [items]="sortOptions"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="sortBy">
        </ng-select>
    </div>
</div>
<app-products-vinyl *ngIf="activeCategory === 'Vinyl'" class="smooth-fade-in"></app-products-vinyl>
<app-product-list *ngIf="activeCategory === 'Merch'" class="smooth-fade-in"></app-product-list>