import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(
    protected http: HttpClient,
    private titleService: Title
  ) { }

  name: string = "";
  email: string = "";
  message: string = "";
  privacyRead = false;

  baseUrl = environment.api;
  sending = false;
  success = false;
  validateNote = false;
  err;

  ngOnInit(): void {
    this.titleService.setTitle('Trust In Wax - Contact');
  }

  submit(e: Event) {
    e.preventDefault();
    this.validateForm();
  }

  validateForm() {
    if ( this.name === "" || this.email === "" || this.message === "" ||
        !this.email.match('[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*')
    ) {
      this.validateNote = true;
    } else {
      if (this.privacyRead) {
        this.validateNote = false;
        this.sendMail();
      }
    }
  }

  sendMail() {
    this.sending = true;

    let body =
    {
      "name": this.name,
      "email": this.email,
      "message": this.message,
      "date": new Date()
    }

    return this.http.post(this.baseUrl + 'inbox', body).toPromise()
    .then(res => {
      this.sending = false,
      this.err = undefined,
      this.validateNote = false,
      this.success = true
    })
    .catch(err => {
      this.err = err;
      this.validateNote = false,
      this.sending = false;
    });
  }

}
