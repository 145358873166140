import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ShopService } from 'src/services/shop.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  activeCategory;
  activeRoute;
  categories = ['Vinyl', 'Merch'];
  sortBy: string;
  sortingSub: Subscription;
  sortOptions = [
    {
      id: 'date-new',
      name: 'Date: new to old'
    },
    {
      id: 'date-old',
      name: 'Date: old to new'
    },
    {
      id: 'name-desc',
      name: 'Name: descending'
    },
    {
      id: 'name-asc',
      name: 'Name: ascending'
    },
    {
      id: 'price-desc',
      name: 'Price: low to high'
    },
    {
      id: 'price-asc',
      name: 'Price: high to low'
    }
  ];


  constructor(
    private route: Router,
    private shopService: ShopService,
    private titleService: Title
    ) { }

  ngOnInit(): void {
    this.setCategoryByRoute();
    this.sortingSub = this.shopService.getSortingSetting().subscribe(setting => {
      this.setSortSetting(setting);
    });
  }

  ngOnDestroy() {
    this.sortingSub.unsubscribe();
  }

  setCategoryByRoute() {
    let route = this.route.url;
    this.route.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          route = this.route.url
        }
      }
    );

    if(route === '/shop') {
      this.activeCategory = 'Vinyl';
      this.titleService.setTitle('Trust In Wax - Shop - Vinyl');
    }
    if(route === '/shop/vinyl') {
      this.activeCategory = 'Vinyl';
      this.titleService.setTitle('Trust In Wax - Shop - Vinyl');
    }
    if(route === '/shop/merch') {
      this.activeCategory = 'Merch';
      this.titleService.setTitle('Trust In Wax - Shop - Merch');
    }
  }

  setSortSetting(setting: string) {
    this.sortBy = setting;
  }

  updateSorting() {
    if(this.sortBy !== null) {
      this.shopService.setSortingSetting(this.sortBy);
    } else {
      this.shopService.resetSorting();
    }
  }

  setLink(category: string) {
    const url = 'shop/' + category.toLowerCase();
    this.route.navigateByUrl(url).then(e => {
      
    });
  }

  setActiveCategory(name) {
    this.activeCategory = name;
  }

}


