import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  vinyls;
  merch;
  sortBy;
  sortByBehaviorSubject : BehaviorSubject<string>;

  constructor(
    private apiService: ApiService,
  ) {
    this.sortByBehaviorSubject = new BehaviorSubject<string>('date-new');
   }

  async getVinylProducts() {
    if(this.vinylsExist()) {
      return this.vinyls;
    } else {
      await this.fetchVinylProducts();
      return this.vinyls;
    }
  }

  async fetchVinylProducts() {
    await this.apiService.getVinylProducts().toPromise().then((res: any) => {
      
        this.vinyls = res.data
        //this.getProductImages()
    });
  }

  getVinylBySuffix(suffix) {
    
    console.log(this.vinyls)

    for(let vinyl of this.vinyls) {
      if (vinyl.url_suffix === suffix) {
        return vinyl
      }
    }
  }

  async getProductImages() {
    let currEntry = {};
    for(var i = 0; i < this.vinyls.length; i++) {
      currEntry = this.vinyls[i];
      await this.getFile(this.vinyls[i].cover_image).then(res => {
        let img = {
          img: res
        };
        Object.assign(currEntry, img),
        this.getPreviewSound();
      });
    }
  }

  async getPreviewSound() {

    let currEntry = {};
    for(var i = 0; i < this.vinyls.length; i++) {
      currEntry = this.vinyls[i];
      await this.getFile(this.vinyls[i].preview_sounds).then(res => {
        let audio = {
          audio: res
        };
        Object.assign(currEntry, audio)
      });

    }
  }

  async getFile(id) {
    console.log('fetFile')
    console.log(id)
    let result;
    if(id !== null) {
      await this.apiService.getFile(id).toPromise().then((res: any) => {
        result = res.data.data;
      });
    }

    return result;
  }


  vinylsExist() {
    if (this.vinyls) {
      return true;
    } else {
      return false;
    }
  }


  // Sorting

  getSortingSetting(): Observable<any> {
    return this.sortByBehaviorSubject.asObservable();
  }

  setSortingSetting(setting: string) {
      this.sortByBehaviorSubject.next(setting)
  }

  resetSorting() {
    this.sortByBehaviorSubject.next("date-new");
  }

}
