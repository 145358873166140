<app-error *ngIf="error"></app-error>
<div *ngFor="let product of products" class="product-entry">
    <div class="image-wrapper">
        <div class="sbl-circ-ripple" *ngIf="!product.cover_image"></div>
        <img *ngIf="product.cover_image" [src]="product.cover_image.data.thumbnails[3].url" class="fade-in">
    </div>
    <div class="descr">
        <div class="section-1">
            <div class="title">
                <div class="name">{{ product.name }}</div>
                <div class="tiw-no">{{ product.tiw_no }}</div>
            </div>
            <div class="price">{{ product.price }} €
                <p class="vat">
                    incl VAT + Shipping
                </p>
            </div>
        </div>
        <div class="teaser">{{ product.teaser }}</div>

        <div class="options">
            <div 
                *ngIf="product.preview_sounds"
                class="fade-in player"
                plyr 
                plyrType="audio"
                [plyrPlaysInline]="true"
                [plyrOptions]="plyrOptions"
                [plyrSources]="product.preview_sounds.data.full_url" 
                (plyrInit)="player = $event" 
                (plyrPlay)="played($event)">
            </div>

            <button 
                mat-button
                *ngIf="product.cover_image"
                class="btn fade-in"
                [routerLink]="['/shop/vinyl', product.url_suffix]" 
                routerLinkActive="active">
                <div class="inner">
                    Details
                    <div [inlineSVG]="'/assets/icons/teenyicons/arrow-right.svg'" class="icon"></div>
                </div>
            </button>

            <button 
                mat-button
                *ngIf="product.cover_image"
                class="btn add snipcart-add-item fade-in"
                [attr.data-item-weight]="product.weight"
                [attr.data-item-name]="product.name"
                [attr.data-item-id]="'vinyl_' + product.id"
                [attr.data-item-price]="product.price"
                [attr.data-item-description]="product.teaser"
                [attr.data-item-image]="product.cover_image.data.thumbnails[3].url"
                [attr.data-item-has-taxes-included]="true"
                [attr.data-item-url]="url">
                <div class="inner">
                    Add to cart
                    <div [inlineSVG]="'/assets/icons/teenyicons/add-to-cart.svg'" class="icon"></div>
                </div>
            </button>
        </div>
    </div>
</div>