import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../../services/api.service';

import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-9';
import { ShopService } from 'src/services/shop.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-vinyl-detail',
  templateUrl: './vinyl-detail.component.html',
  styleUrls: ['./vinyl-detail.component.scss']
})
export class VinylDetailComponent implements OnInit {

  id;
  product;
  products;
  imageDetails;
  error;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  player: Plyr;
  plyrOptions = {
    controls: ['play', 'progress']
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private shopService: ShopService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.gallerySetup();
    this.getProductBySuffix();
    
  }

  async getProduct(id) {
    await this.apiService.getVinylProduct(id).toPromise().then((res: any) => {
      this.product = res.data,
      this.titleService.setTitle('Trust In Wax - Shop: ' + this.product.name);
      console.log(res),
      this.getProductImage(this.product.cover_image.id);
    }).catch(() =>
      this.router.navigate(['404'])
    );
  }

  getProductBySuffix() {
      this.shopService.getVinylProducts().then(res => {
        this.error = false;
        this.products = res;
        for(let prod of this.products) {
          if(prod.url_suffix === this.id) {
            this.product = prod;
            this.titleService.setTitle('Trust In Wax - Shop: ' + this.product.name);
            break;
          }
        }
       this.getProductImage(this.product.cover_image.id);
      }).catch(err => {
        console.log(err)
        this.error = true;
      });
  }

  async getProductImage(id) {
    await this.apiService.getFile(id).toPromise().then((res: any) => {
      this.imageDetails = res.data.data,
      this.galleryImages = [
        {
            small: this.imageDetails.thumbnails[3].url,
            medium: this.imageDetails.thumbnails[3].url,
            big: this.imageDetails.full_url
        }
      ],
      this.getGalleryImages();
    });
  }

  getGalleryImages() {
      if(this.product.gallery_image_1 !== null) {
        this.galleryImagesSetup(this.product.gallery_image_1);
      }
      if(this.product.gallery_image_2 !== null) {
        this.galleryImagesSetup(this.product.gallery_image_2);
      }
      if(this.product.gallery_image_3 !== null) {
        this.galleryImagesSetup(this.product.gallery_image_3);
      }
      if(this.product.gallery_image_4 !== null) {
        this.galleryImagesSetup(this.product.gallery_image_4);
      }
  }

  galleryImagesSetup(img) {
    img = 
    {
        small: img.data.thumbnails[3].url,
        medium: img.data.full_url,
        big: img.data.full_url
    }
    this.galleryImages.push(img);
  }

  gallerySetup() {
    this.galleryOptions = [
      {
          width: '300px',
          height: '365px',
          thumbnailsColumns: 4,
          thumbnailSize: NgxGalleryImageSize.Contain,
          imageAnimation: NgxGalleryAnimation.Slide,
          previewCloseOnClick: true,
          previewCloseOnEsc: true
      },
/*       // max-width 800
      {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      }, */
      // max-width 400
      {
          breakpoint: 400,
          preview: true
      }
    ];
  }

  played(event: Plyr.PlyrEvent) {
    //console.log('played', event);
  }

}
