<app-backlink route="releases" linkText="releases" class="smooth-fade-in"></app-backlink>
<app-error *ngIf="error"></app-error>
<div class="release-wrapper smooth-fade-in">
    <div *ngIf="release">
        <h1 class="main-site-title">{{release.title}}</h1>
        <div class="row">
            <div class="col first">
                <div class="youtube" *ngIf="release.youtube_video">
                    <iframe 
                        width="100%" 
                        height="320" 
                        [src]="sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/' + release.youtube_video)" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>
                <div class="txt-release" [innerHTML]="release.release_description"></div>

                <h3>Special thanks to</h3>
                <div class="thx" [innerHTML]="release.thx_to_text"></div>
            </div>


            <div class="col sidebar">
                <div class="image-wrapper">
                    <div *ngIf="!release.cover" class="sbl-circ-ripple"></div>
                    <img *ngIf="release.cover" [src]="release.cover.data.thumbnails[5].url" class="fade-in">
                </div>

                <div class="section release-details">
                    <div class="desc-title">Release details</div>
                    <div class="artist" *ngIf="release.label_artist">{{release.label_artist.name}}</div>
                    <div class="title">{{release.title}}</div>

                    <div class="no">{{release.no}}</div>
                    <div class="release-day">Released on {{release.release_day}}</div>
                    <div class="chips">
                        <div *ngIf="release.digital_release" class="chip">Digital</div>
                        <div *ngIf="release.tape_release"  class="chip">Tape</div>
                        <div *ngIf="release.vinyl_release" class="chip">Vinyl</div>
                    </div>
                </div>

                <div class="section tracks-details">
                    <div class="desc-title">Tracklist</div>
                    <div class="tracklist" [innerHTML]="release.tracklist"></div>
                </div>

                <div class="section platforms">
                    <div class="desc-title">Available at</div>
                    <a *ngIf="release.link_amazon" href={{release.link_amazon}} target="_blank" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/font-awesome/brands/amazon.svg'" class="icon"></div>
                        <div>Amazon</div>
                    </a>
                    <a *ngIf="release.link_bandcamp" href={{release.link_bandcamp}} target="_blank" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/font-awesome/brands/bandcamp.svg'" class="icon"></div>
                        <div>Bandcamp</div>
                    </a>
                    <a *ngIf="release.link_hhv" href={{release.link_hhv}} target="_blank" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/hhv.svg'" class="icon"></div>
                        <div>hhv</div>
                    </a>
                    <a *ngIf="release.link_itunes" href={{release.link_itunes}} target="_blank" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/font-awesome/brands/itunes.svg'" class="icon"></div>
                        <div>iTunes</div>
                    </a>
                    <a *ngIf="release.link_juno" href={{release.link_juno}} target="_blank" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/font-awesome/solid/angle-right.svg'" class="icon"></div>
                        <div>Juno Records</div>
                    </a>
                    <a *ngIf="release.link_spotify" href={{release.link_spotify}} target="_blank" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/font-awesome/brands/spotify.svg'" class="icon"></div>
                        <div>Spotify</div>
                    </a>
                    <a *ngIf="release.shop_id" [routerLink]="['/shop/vinyl/detail', release.shop_id]" class="social-media-link">
                        <div [inlineSVG]="'/assets/icons/trust-in-wax-t.svg'" class="icon"></div>
                        <div>Trust In Wax</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>