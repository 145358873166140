import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.animate();
  }


  animate() {
    const headlineWax = document.querySelector('.head-wax');
    headlineWax.innerHTML = headlineWax.textContent.replace(/\S/g, "<span class='headline-wax-letter'>$&</span>");

    const headlineTrust = document.querySelector('.head-trust');
    headlineTrust.innerHTML = headlineTrust.textContent.replace(/\S/g, "<span class='headline-trust-letter'>$&</span>");

    const textWax = document.querySelector('.wax');
    textWax.innerHTML = textWax.textContent.replace(/\S/g, "<span class='letter-wax'>$&</span>");

    const textTrust = document.querySelector('.trust');
    textTrust.innerHTML = textTrust.textContent.replace(/\S/g, "<span class='letter-trust'>$&</span>");




    // show 'trust'

    anime.timeline().add({
      targets: '.headlineTrust, .headline-trust-letter',
      opacity: [0,1],
      easing: "easeInOutQuad",
      duration: 1500,
      delay: (el, i) => 150 * (i+1)
    });

    anime.timeline().add({
      targets: '.trust, .letter-trust',
      opacity: [0,1],
      easing: "easeInOutQuad",
      duration: 500,
      delay: (el, i) => 10 * (i+1)
    }, 1500);


    // move down

    anime.timeline().add({
      targets: '.head-trust, .trust',
      translateY: '35vh',
      easing: "easeInOutQuad",
      duration: 750,
      delay: (el, i) => 10 * (i+1)
    },'+=6000');


    // show 'wax'

    anime.timeline().add({
      targets: '.headlineWax, .headline-wax-letter',
      opacity: [0,1],
      easing: "easeInOutQuad",
      duration: 1500,
      delay: (el, i) => 150 * (i+1)
    }, '+=5500');

    anime.timeline().add({
      targets: '.wax, .letter-wax',
      opacity: [0,1],
      easing: "easeInOutQuad",
      duration: 500,
      delay: (el, i) => 10 * (i+1)
    }, '+=6000');

  }
}


