import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtistsService } from 'src/services/artists.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-artists-detail',
  templateUrl: './artists-detail.component.html',
  styleUrls: ['./artists-detail.component.scss']
})
export class ArtistsDetailComponent implements OnInit {

  routeParam;
  artist;
  error = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private artistService: ArtistsService,
    public sanitizer: DomSanitizer,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.routeParam = this.route.snapshot.paramMap.get('id');
    this.artistSetup();
  }

  artistSetup() {
    if (this.artistService.artistsExist()) {
      this.error = false;
      this.artist = this.artistService.getArtistByRouteParam(this.routeParam);
      if(this.artist === undefined) {
        this.router.navigate(['404']);
      }
      this.titleService.setTitle('Trust In Wax - ' + this.artist.name);
    } else {
      this.artistService.fetchArtists().then(res => {
        this.error = false;
        this.artist = this.artistService.getArtistByRouteParam(this.routeParam);

        if(this.artist === undefined) {
          this.router.navigate(['404']);
        }
        this.titleService.setTitle('Trust In Wax - ' + this.artist.name);
      }).catch(() => 
        this.error = true
      )
    }
  }

}
