<app-backlink route="blog" linkText="blog" class="smooth-fade-in"></app-backlink>
<div class="blog-entry-wrapper smooth-fade-in">
    <app-error *ngIf="error"></app-error>
    <div class="headline">
        <h1 class="main-site-title blog-title" *ngIf="!error && blogEntry">{{ blogEntry.title }}</h1>
    </div>
    <div class="image-wrapper" *ngIf="blogEntry">

        <div class="sbl-circ-ripple" *ngIf="!blogEntry.image"></div>
        <img *ngIf="blogEntry.image" [src]="blogEntry.image.data.full_url" class="fade-in">
        <div class="date">
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M3.5 0v5m8-5v5m-10-2.5h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="currentColor"></path></svg>  
            {{ blogEntry.article_date | date: 'dd.MM.yyyy' }}
        </div>
    </div>
    <div *ngIf="entry" class="txt" [innerHTML]="entry"></div>
    <div class="categories" *ngIf="blogEntry">
        <div class="category-chip" *ngFor="let category of blogEntry.categories" [routerLink]="['/blog/category/', category.blog_categories_id.url_suffix]">
            {{ category.blog_categories_id.name }}
        </div>
    </div>
</div>