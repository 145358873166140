import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = environment.api;
  filesUrl = environment.filesApi;

  constructor(protected http: HttpClient) { }

  getBlogEntries() {
    return this.http.get(this.baseUrl + 'blog?sort=-article_date&fields=*.*.*');
  }

  getBlogEntry(id) {
    return this.http.get(this.baseUrl + `blog/${id}`);
  }

  getBlogCategories() {
    return this.http.get(this.baseUrl + 'blog_categories');
  }

  getArtists() {
    return this.http.get(this.baseUrl + 'label_artists?sort=name&fields=*.*');
  }

  getArtist(id) {
    return this.http.get(this.baseUrl + `label_artists/${id}`)
  }

  getReleases() {
    return this.http.get(this.baseUrl + 'label_releases?sort=-release_day&fields=*.*');
  }


  // SHOP

  getProducts() {
    return this.http.get(this.baseUrl + 'products?sort=-id');
  }

  getProduct(id) {
    return this.http.get(this.baseUrl + `products/${id}`);
  }

  getVinylProducts() {
    return this.http.get(this.baseUrl + 'shop_vinyl?sort=-id&fields=*.*');
  }

  getVinylProduct(id) {
    return this.http.get(this.baseUrl + `shop_vinyl/${id}`);
  }

  // UTIL

  getFile(id) {
    return this.http.get(this.filesUrl + `${id}`);
  }


}
