import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-backlink',
  templateUrl: './backlink.component.html',
  styleUrls: ['./backlink.component.scss']
})
export class BacklinkComponent implements OnInit {

  @Input() route: string;
  @Input() linkText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
